import { useState } from 'react'

export const useWebWorker = () => {
  const [working, setWorking] = useState(false)

  const initWorker = (fn) => {
    const workerHandler = (fn) => {
      onmessage = e => {
        postMessage(fn(e.data))
      }
    }

    return (data) => {
      setWorking(true)
      const worker = new Worker(
        URL.createObjectURL(new Blob([`(${workerHandler})(${fn})`]))
      )

      return new Promise(((resolve, reject) => {
        worker.onmessage = e => {
          worker.terminate()
          setWorking(false)
          resolve(e.data)
        }
        worker.onerror = e => {
          worker.terminate()
          setWorking(false)
          reject(e.message)
        }
        worker.postMessage(data)
      }))
    }
  }

  return {initWorker, working}
}