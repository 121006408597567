import { AUTH_LOGIN, AUTH_LOGOUT } from '../types/types'

const storageName = 'userDataCodeminer'

/**
 *
 * @param data (token, user)
 * @returns {{payload, type: string}}
 */
export const login = (data) => {
 localStorage.setItem(storageName, JSON.stringify({userId: data.user.id, token: data.token, user: data.user}))
 return {
  type: AUTH_LOGIN,
  payload: data
 }
}

export const logout = () => {
 localStorage.removeItem(storageName)
 return {
  type: AUTH_LOGOUT
 }
}

export const checkLogged = () => {
 return async dispatch => {
  const data = JSON.parse(localStorage.getItem(storageName))

  if (data && data.token && data.user) {
   let body = JSON.stringify({ userId: data.user.id })

   let headers = {}
   headers['Content-Type'] = 'application/json'
   headers.authorization = 'Bearer ' + data.token

   const response = await fetch(`/api/auth/check`, { method: 'POST', body, headers })
   const res = await response.json()

   if (res.message !== 'Нет авторизации' && res?.isUser !== false) {
    dispatch(login(data))
    return 'auth'
   } else {
    dispatch(logout())
    return 'not auth'
   }
  }

  return 'not auth'
 }
}