import React from 'react'

export const Loader = ({size, pt=3, pb=0}) => {
  if (!size) size = '100'
  return (
    <div style={{display: 'flex', justifyContent: 'center', paddingTop: `${pt}rem`, paddingBottom: `${pb}rem`, width: '100%'}}>
      <div className="spinner-border text-primary" role="status" style={{width: `${size}px`, height: `${size}px`}}>
        <span className="visually-hidden">Загрузка...</span>
      </div>
    </div>
  )
}
