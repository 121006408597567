export const icons = {
  mosaic: (color = 'currentColor') => {
    return (
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0858 1.58579C10.7107 1.96086 10.5 2.46957 10.5 3V4C10.5 4.26522 10.3946 4.51957 10.2071 4.70711C10.0196 4.89464 9.76522 5 9.5 5H6.5C6.23478 5 5.98043 5.10536 5.79289 5.29289C5.60536 5.48043 5.5 5.73478 5.5 6V9C5.5 9.26522 5.39464 9.51957 5.20711 9.70711C5.01957 9.89464 4.76522 10 4.5 10H3.5C2.96957 10 2.46086 10.2107 2.08579 10.5858C1.71071 10.9609 1.5 11.4696 1.5 12C1.5 12.5304 1.71071 13.0391 2.08579 13.4142C2.46086 13.7893 2.96957 14 3.5 14H4.5C4.76522 14 5.01957 14.1054 5.20711 14.2929C5.39464 14.4804 5.5 14.7348 5.5 15V18C5.5 18.2652 5.60536 18.5196 5.79289 18.7071C5.98043 18.8946 6.23478 19 6.5 19H9.5C9.76522 19 10.0196 18.8946 10.2071 18.7071C10.3946 18.5196 10.5 18.2652 10.5 18V17C10.5 16.4696 10.7107 15.9609 11.0858 15.5858C11.4609 15.2107 11.9696 15 12.5 15C13.0304 15 13.5391 15.2107 13.9142 15.5858C14.2893 15.9609 14.5 16.4696 14.5 17V18C14.5 18.2652 14.6054 18.5196 14.7929 18.7071C14.9804 18.8946 15.2348 19 15.5 19H18.5C18.7652 19 19.0196 18.8946 19.2071 18.7071C19.3946 18.5196 19.5 18.2652 19.5 18V15C19.5 14.7348 19.3946 14.4804 19.2071 14.2929C19.0196 14.1054 18.7652 14 18.5 14H17.5C16.9696 14 16.4609 13.7893 16.0858 13.4142C15.7107 13.0391 15.5 12.5304 15.5 12C15.5 11.4696 15.7107 10.9609 16.0858 10.5858C16.4609 10.2107 16.9696 10 17.5 10H18.5C18.7652 10 19.0196 9.89464 19.2071 9.70711C19.3946 9.51957 19.5 9.26522 19.5 9V6C19.5 5.73478 19.3946 5.48043 19.2071 5.29289C19.0196 5.10536 18.7652 5 18.5 5H15.5C15.2348 5 14.9804 4.89464 14.7929 4.70711C14.6054 4.51957 14.5 4.26522 14.5 4V3C14.5 2.46957 14.2893 1.96086 13.9142 1.58579C13.5391 1.21071 13.0304 1 12.5 1C11.9696 1 11.4609 1.21071 11.0858 1.58579Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  code: (color = 'currentColor') => {
    return (
      <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 17L13.5 1M17.5 5L21.5 9L17.5 13M5.5 13L1.5 9L5.5 5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  doc: (color = 'currentColor') => {
    return (
      <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 3H3.5C2.96957 3 2.46086 3.21071 2.08579 3.58579C1.71071 3.96086 1.5 4.46957 1.5 5V17C1.5 17.5304 1.71071 18.0391 2.08579 18.4142C2.46086 18.7893 2.96957 19 3.5 19H13.5C14.0304 19 14.5391 18.7893 14.9142 18.4142C15.2893 18.0391 15.5 17.5304 15.5 17V5C15.5 4.46957 15.2893 3.96086 14.9142 3.58579C14.5391 3.21071 14.0304 3 13.5 3H11.5M5.5 3C5.5 3.53043 5.71071 4.03914 6.08579 4.41421C6.46086 4.78929 6.96957 5 7.5 5H9.5C10.0304 5 10.5391 4.78929 10.9142 4.41421C11.2893 4.03914 11.5 3.53043 11.5 3M5.5 3C5.5 2.46957 5.71071 1.96086 6.08579 1.58579C6.46086 1.21071 6.96957 1 7.5 1H9.5C10.0304 1 10.5391 1.21071 10.9142 1.58579C11.2893 1.96086 11.5 2.46957 11.5 3M8.5 10H11.5M8.5 14H11.5M5.5 10H5.51M5.5 14H5.51" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  test: (color = 'currentColor') => {
    return (
      <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.72743 6.44721C8.658 6.58607 8.5572 6.70685 8.433 6.8L3.1 10.8C2.95143 10.9114 2.77477 10.9793 2.58981 10.996C2.40484 11.0126 2.21889 10.9775 2.05279 10.8944C1.88668 10.8114 1.74698 10.6837 1.64935 10.5257C1.55171 10.3678 1.5 10.1857 1.5 10V2C1.5 1.81429 1.55171 1.63225 1.64935 1.47427C1.74698 1.31629 1.88668 1.18863 2.05279 1.10557C2.21889 1.02252 2.40484 0.987363 2.58981 1.00404C2.77477 1.02072 2.95143 1.08857 3.1 1.2L8.433 5.2C8.5572 5.29315 8.658 5.41393 8.72743 5.55279C8.79685 5.69164 8.833 5.84476 8.833 6C8.833 6.15525 8.79685 6.30836 8.72743 6.44721Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.7274 6.44721C16.658 6.58607 16.5572 6.70685 16.433 6.8L11.1 10.8C10.9514 10.9114 10.7748 10.9793 10.5898 10.996C10.4048 11.0126 10.2189 10.9775 10.0528 10.8944C9.88668 10.8114 9.74698 10.6837 9.64935 10.5257C9.55171 10.3678 9.5 10.1857 9.5 10V2C9.5 1.81429 9.55171 1.63225 9.64935 1.47427C9.74698 1.31629 9.88668 1.18863 10.0528 1.10557C10.2189 1.02252 10.4048 0.987363 10.5898 1.00404C10.7748 1.02072 10.9514 1.08857 11.1 1.2L16.433 5.2C16.5572 5.29315 16.658 5.41393 16.7274 5.55279C16.7969 5.69164 16.833 5.84476 16.833 6C16.833 6.15525 16.7969 6.30836 16.7274 6.44721Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  gear: (color = 'currentColor') => {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.175 4.317C13.749 2.561 11.251 2.561 10.825 4.317C10.549 5.452 9.249 5.99 8.253 5.382C6.709 4.442 4.943 6.209 5.883 7.752C6.0243 7.98375 6.10889 8.24559 6.12987 8.51621C6.15085 8.78683 6.10764 9.05859 6.00375 9.30935C5.89985 9.56011 5.73822 9.7828 5.532 9.95929C5.32578 10.1358 5.0808 10.2611 4.817 10.325C3.061 10.751 3.061 13.249 4.817 13.675C5.08056 13.7391 5.32529 13.8645 5.53127 14.0409C5.73726 14.2174 5.8987 14.44 6.00247 14.6906C6.10624 14.9412 6.14942 15.2128 6.12848 15.4832C6.10755 15.7537 6.0231 16.0153 5.882 16.247C4.942 17.791 6.709 19.557 8.252 18.617C8.48375 18.4757 8.74559 18.3911 9.01621 18.3701C9.28683 18.3491 9.55859 18.3924 9.80935 18.4963C10.0601 18.6001 10.2828 18.7618 10.4593 18.968C10.6358 19.1742 10.7611 19.4192 10.825 19.683C11.251 21.439 13.749 21.439 14.175 19.683C14.2391 19.4194 14.3645 19.1747 14.5409 18.9687C14.7174 18.7627 14.94 18.6013 15.1906 18.4975C15.4412 18.3938 15.7128 18.3506 15.9832 18.3715C16.2537 18.3924 16.5153 18.4769 16.747 18.618C18.291 19.558 20.057 17.791 19.117 16.248C18.9757 16.0162 18.8911 15.7544 18.8701 15.4838C18.8491 15.2132 18.8924 14.9414 18.9963 14.6907C19.1001 14.4399 19.2618 14.2172 19.468 14.0407C19.6742 13.8642 19.9192 13.7389 20.183 13.675C21.939 13.249 21.939 10.751 20.183 10.325C19.9194 10.2609 19.6747 10.1355 19.4687 9.95905C19.2627 9.78258 19.1013 9.55999 18.9975 9.30938C18.8938 9.05877 18.8506 8.78721 18.8715 8.51677C18.8924 8.24634 18.9769 7.98466 19.118 7.753C20.058 6.209 18.291 4.443 16.748 5.383C16.5162 5.5243 16.2544 5.60889 15.9838 5.62987C15.7132 5.65085 15.4414 5.60764 15.1907 5.50375C14.9399 5.39985 14.7172 5.23822 14.5407 5.032C14.3642 4.82578 14.2389 4.5808 14.175 4.317Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.6213 14.1213C15.1839 13.5587 15.5 12.7956 15.5 12C15.5 11.2044 15.1839 10.4413 14.6213 9.87868C14.0587 9.31607 13.2956 9 12.5 9C11.7044 9 10.9413 9.31607 10.3787 9.87868C9.81607 10.4413 9.5 11.2044 9.5 12C9.5 12.7956 9.81607 13.5587 10.3787 14.1213C10.9413 14.6839 11.7044 15 12.5 15C13.2956 15 14.0587 14.6839 14.6213 14.1213Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  logout: (color = 'currentColor') => {
    return (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 13L19 9M19 9L15 5M19 9H5M11 13V14C11 14.7956 10.6839 15.5587 10.1213 16.1213C9.55871 16.6839 8.79565 17 8 17H4C3.20435 17 2.44129 16.6839 1.87868 16.1213C1.31607 15.5587 1 14.7956 1 14V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1H8C8.79565 1 9.55871 1.31607 10.1213 1.87868C10.6839 2.44129 11 3.20435 11 4V5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },
  rBracket: (color = '#94A3B8') => {
    return (
      <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.125 1.95801L6.66667 7.49967L1.125 13.0413" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  },

}
