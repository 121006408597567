import { icons }  from './Icons'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/actions/auth.actions'

export const NavBar = ({className = '', visible = true}) => {
  const dispatch = useDispatch()
  const history = useNavigate()

  const selected = ({ isActive }) => {
    return isActive ? 'topNav_el topNav_active' : 'topNav_el'
  }

  return (
    <div className={`${className} topNav ${!visible && 'd-none'}`}>
      <div className="topNav_left">
        <NavLink to="/arch" className={selected}><span>{icons.mosaic()}</span>Архитетктура</NavLink>
        <NavLink to="/code" className={selected}><span>{icons.code()}</span>Код</NavLink>
        <NavLink to="/docs" className={selected}><span>{icons.doc()}</span>Документация</NavLink>
        <NavLink to="/test" className={selected}><span>{icons.test()}</span>Тестирование</NavLink>
        <NavLink to="/user" className={selected}><span>{icons.gear()}</span>Настройки</NavLink>
      </div>
      <div className="topNav_right">
        <div className="topNav_el" onClick={() => {
          dispatch(logout())
          history('/user/login')
        }}><span>{icons.logout()}</span></div>
      </div>
    </div>
  )
}