import { Link, useNavigate } from 'react-router-dom'

export const NotFound = () => {
  const navigate = useNavigate()

  return (
    <div className="container notfound">
      <h1>404, нет такой странички.</h1>
      <button className="btn btn-outline-info" onClick={() => {navigate(-1)}}>Вернуться</button>
    </div>
  )
}