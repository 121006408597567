import React, { useState, useEffect } from 'react'
import { Icon } from '../../components/Icon'
import { useDispatch, useSelector } from 'react-redux'
import { updateForm } from '../../redux/actions/arch.actions'

export const FlowSidebar = ({ blocks, createBlock }) => {
  const arch = useSelector(state => state.arch)
  const dispatch = useDispatch()
  const [action, setAction] = useState(arch.flowSide)
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    if (blocks.length) setFiltered(blocks.filter(el => el.type === arch.flowSide))
  }, [blocks, arch.flowSide])

  const onDragStart = (event, nodeType, b) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.setData('b', JSON.stringify(b))
    event.dataTransfer.effectAllowed = 'move'
  }

  return (
    <aside style={{ zIndex: 1000 }}>
      <p id="header" className="mb-3">Блоки</p>

      <div id="search" className="mb-3">
        <div className="input-field text-gray w-100">
          <input
            placeholder="Search"
            type="text"
            id="blck-search"
            className="form-control w-100"
            name="search"
            autoComplete="off"
            onKeyUp={(key) => {
              // setSearching(true)
              key.target.value
                ? setFiltered(blocks.length ? blocks.filter(el => el.name.toLowerCase().includes(key.target.value.toLowerCase()) || el.description.toLowerCase().includes(key.target.value.toLowerCase())) : [])
                : setFiltered(blocks.length ? blocks : [])
            }}
          />
        </div>
      </div>

      <div id="subnav" className="d-flex justify-content-between align-content-center">
        <div id="actions" className={`side d-flex justify-content-center ${action === 'Action' ? 'navactive' : 'navdisabled'}`}
             onClick={() => {
               setAction('Action')
               dispatch(updateForm({flowSide: 'Action'}))
               // setSearching(false)
               setFiltered(blocks.length ? blocks.filter(el => el.type === 'Action') : [])
               document.getElementById('blck-search').value = ''
             }}>
          <div className="d-flex justify-content-center align-content-center">
            <div><Icon name="flash" size="20px"/></div>
            <span>Действия</span>
          </div>
        </div>
        <div id="loggers" className={`side d-flex justify-content-center ${action === 'Block' ? 'navactive' : 'navdisabled'}`}
             onClick={() => {
               setAction('Block')
               dispatch(updateForm({flowSide: 'Block'}))
               // setSearching(false)
               setFiltered(blocks.length ? blocks.filter(el => el.type === 'Block') : [])
               document.getElementById('blck-search').value = ''
             }}>
          <div className="d-flex justify-content-center align-content-center">
            <div><Icon name="box" size="20px"/></div>
            <span>Блоки</span>
          </div>
        </div>
      </div>

      <div id="blocklist">
        {filtered && filtered.length ? filtered.map(b => {
          return (
            <React.Fragment key={b.id}>
              <div className="blockelem create-flowy noselect dndnode d-flex justify-content-start"
                   onDragStart={(event) => onDragStart(event, 'special', b)} draggable>
                <div className="grabme">
                  {b.type === 'Action' ? <Icon name="flash" size="20px" mt="-7px"/> :
                    <Icon name="box" size="20px" mt="-7px"/>}
                </div>
                <div className="blockin d-flex flex-column">
                  <div className="blocktext">
                    <p className="blocktitle">{b.name}</p>
                    <p className="blockdesc">{b.description}</p>
                  </div>
                </div>
              </div>
            </React.Fragment>)
        }) : <></>}
        <div className="addBtn text-gray mt-3" onClick={() => {
          // add block
          createBlock()
        }}>
          {/*{action === 'Action' ? '+ add action' : '+ add block'}*/}
          + add new block
        </div>
      </div>
    </aside>
  )
}