import { NavBar } from './NavBar'
import { Sidebar } from './Sidebar'

const Layout = ({children, sidebar = true, footer = false, footerText = '', footerPerc = 0, isTest = false}) => {
  return (
    <div className="layout">
      <NavBar isTest={isTest}/>
      <div className="layout_wrapper">
        <Sidebar visible={sidebar} isTest={isTest}/>
        <main className="container-fluid h-100">
          {children}
        </main>
      </div>
      <footer className={`${!footer && 'd-none'}`}>
        <div className="absolute stepper" style={{width: `${footerPerc}vw`}}/>
        <span className="text-base">{footerText}</span>
      </footer>
    </div>
  )
}

export {Layout}