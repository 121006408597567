import { Layout } from '../../components/Layout'
import { useEffect, useRef, useState } from 'react'
// import { useSpellCheck } from '../../hooks/spellCheck.hook'
import { PopupEl } from '../../components/PopupEl'
import { Loader } from '../../components/Loader'
import { useCheckWords } from '../../hooks/checkWords.hook'
//import { WordSpan } from '../../components/WordSpan'


export const Docs = () => {
  // const spellcheck = useSpellCheck()

  const { working, checkIt, legend } = useCheckWords()
  const textBox = useRef(null)
  const [show, setShow] = useState(false)

  const showPopup = e => {
    if (e.key === '/') {
      e.preventDefault()
      e.stopPropagation()
      setShow(prev => !prev)
    }
  }

  // On "/" pressed anywhere
  useEffect(() => {
    document.addEventListener('keydown', showPopup)

    // remove listener on unmount component
    return () => {
      document.removeEventListener('keydown', showPopup)
    }
  }, [])

  const downloadTxtFile = (doc) => {
    console.log(doc)
    const element = document.createElement("a");
    const file = new Blob([doc], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "documentation.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  const handleFile = (e) => {
    const content = e.target.result;
    textBox.current.innerHTML = content;
  }

  const handleChangeFile = (file) => {
    let fileData = new FileReader();
    fileData.onloadend = handleFile;
    fileData.readAsText(file);
  }


  return (
    <Layout sidebar={false}>
      <div className="container">
        <div className="wrapper">
          <p className="p-0 m-0"><span className="text-xl f-medium">Анализ требований на полноту и корректность</span></p>
          <span className="text-sm blueGray400">Ввидете ‘/’ чтобы вставить готовый шаблон документа</span>

          <div className="d-flex mt-3 w-100">
            <div className="editContainer">
              {/*popup window*/}
              <div className={`popup position-absolute ${!show ? 'd-none' : 'd-flex flex-column'}`}>
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `Руководство оператора <br><br> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae ea laborum molestiae obcaecati repellendus saepe totam. Reiciendis soluta, unde.`
                  setShow(false)
                }} text={'Руководство оператора'} />
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `Руководство программиста <br><br> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae ea laborum molestiae obcaecati repellendus saepe totam. Reiciendis soluta, unde.`
                  setShow(false)
                }} text={'Руководство программиста'} />
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `Описание алгоритма <br><br> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet beatae ea laborum molestiae obcaecati repellendus saepe totam. Reiciendis soluta, unde.`
                  setShow(false)
                }} text={'Описание алгоритма'} />
                <PopupEl onClick={() => {
                  textBox.current.innerHTML = `Кому не знакома боль в спине? Это, безусловно, одна из главных проблем современного человека. Доктора со всего мира ломают голову над вопросом, как избавить нас от этой напасти. И до недавнего времени казалось, что наука бессильна: сколько копий было сломано, и ни одного абсолютно эффективного средства так и не было найдено!
                                               Но теперь все изменилось! Британские ученые разработали новейший суперэффективный метод лечения болей в спине. Представляем спорт!
                                               Спорт — это уникальный метод решения проблем со здоровьем с доказанной стопроцентной эффективностью!
                                               Спорт доступен каждому! Спортом может заниматься кто угодно: будь то неуверенный в себе подросток или успешный предприниматель. 
                                               Спорт подарит вам прилив сил и энергии на весь день! Это занятие для всей семьи, которое не оставит равнодушным никого, от мала до велика!
                                               Попробуйте спорт прямо сейчас!`
                  setShow(false)
                }} text={'Демо'} />
              </div>

              {/*spellCheck подчёркивает все английские слова*/}
              {working ?
                <Loader />
                :
                <div id='txtar' ref={textBox} className="textEdit" contentEditable={true} spellCheck={false}
                />
              }

              <div className="container">
                <div className="row">
                  <div className="col">
                    <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                      checkIt(textBox.current.innerText).then(res => { textBox.current.innerHTML = res }).catch(e => { console.log('error', e) })
                    }}>Проверить</button>

                    <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                      downloadTxtFile(textBox.current.innerHTML)
                    }}>Скачать</button>
                  </div>
                  <div className="col">
                    <input className="form-control minWidth-136 text-base mt-4 me-2" type={"file"} onChange={e => handleChangeFile(e.target.files[0])}></input>
                  </div>
                </div>
              </div>

              {/*<button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {*/}
              {/*  spellcheck.check(textBox.current.innerHTML)*/}
              {/*    .then(res => {*/}
              {/*      console.log(res)*/}
              {/*      textBox.current.innerHTML = res*/}
              {/*    })*/}
              {/*}}>Test</button>*/}
            </div>

            <div className="legend h-100 d-flex flex-column justify-content-center align-items-center">
              <div id='issues'>

                {/* <p id='word'></p> */}
                <p id='category'></p>
                <p id='advice'></p>
                <span className="text-sm blueGray400">Разрешить слово: Alt+Shift+click по слову</span>
              </div>

              {/* {Object.keys(legend).map(cat => {
                  return (
                    <div className="d-flex align-items-center gap-2" key={cat}>
                      <span className="legendTag" style={{ backgroundColor: legend[cat].color }} />
                      <span className="text-base">{legend[cat].title}</span>
                    </div>
                  )
                })} */}
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}