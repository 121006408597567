export const stopWords = {
  corporateStamps: ['быстро развивающийся', 'в ассортименте', 'в сфере', 'ведущий', 'высокое качество',
    'география продаж', 'динамично развивающаяся', 'дружный коллектив', 'за долгие годы работы', 'зарекомендовать себя',
     'золотой стандарт', 'индивидуальный подход', 'коллектив профессионалов ', 'команда профессионалов',
    'лидер рынка',   'передовые технологии', 'полный цикл', 'полный спектр', 'практичный', 'прозрачный',
    'профессионал своего дела', 'работать на совесть', 'решать задачи бизнеса', 'решения в области', 'решения для повышения'],

  newspaperStamps: ['акула пера', 'альма матер', 'апологет', 'беззаботная школьная жизнь',
    'беззаботная студенческая жизнь', 'белокурая бестия', 'бескрайние просторы',
    'беспрецедентный', 'бить тревогу', 'блюститель', 'британские ученые', 'английские ученые',
    'был рассмотрен вопрос', 'бытует мнение', 'бьет ключом', 'в большинстве случаев',
    'век высоких технологий', 'в век компьютеров', 'в данный момент', 'в компьютерный век',
    'в лучших традициях', 'в мгновение ока ', 'в отчаянных попытках', 'в ход идут', 'великий и могучий',
    'вершина айсберга', 'взрослая жизнь', 'взять на вооружение', 'видавшие виды', 'виновник торжества',
    'власти делают все возможное', 'вобрал в себя воедино', 'воссоздать атмосферу', 'восходящая звезда',
    'вот парадокс', 'вот уже более', 'вот-вот', 'вплетаться ', 'время покажет', 'времяпровождение',
    'все познается в сравнении', 'все средства хороши', 'все цвета радуги', 'всегда есть выбор',
    'всемирная паутина', 'встреча на высшем уровне', 'вызвать немало эмоций', 'высокого полета',
    'выше всяких похвал', 'вышел иа новый уровень', 'глазам не верю', 'глобальная паутина',
    'глобальная сеть', 'горят глаза', 'горячая пока', 'государственная казна',
    'докопаться до истины', 'достойным лучшего применения', 'доступен для комментариев',
    'единственный в своем роде', 'если можно так выразиться', 'за каламбур', 'за рубежом',
    'заверить', 'залог чего-либо', 'заразительный', 'затронуть вопрос', 'захватывать дух',
    'из года в год', 'из достоверных источников стало известно', 'известно ли вам', 'издревле',
    'излишество', 'изобиловать', 'изюминка', 'имеет место', 'имеет место быть', 'иметь право на существование',
    'иметь что-то общее', 'исстари', 'исчерпывающий ответ', 'каждый из нас', 'каждый сам решает', 'казалось бы',
    'как в сказке', 'как грибы после дождя', 'как гром среди ясного неба', 'как и подобает', 'как красная тряпка',
    'как на дрожжах', 'как по волшебству', 'комплекс мер', 'копья сломаны', 'красочно представить',
    'крепко сбитый', 'ландшафт', 'ломать голову', 'любовь к ближнему', 'манящий', 'маховик времени',
    'мировая паутина', 'на вес золота', 'на войне как на войне', 'на лоне', 'на ниве', 'на поприще',
    'на просторах всемирной паутины', 'на самом деле нет', 'наводить мосты', 'наглядно отражать',
    'наказать рублем', 'налаживать сотрудничество', 'напасть', 'напрашиваться сам собой', 
    'насчитывать', 'нашумевший ', 'е жалея красок', 'зазорно', 'не иметь аналогов', 'не может не радовать',
    'не обошлось и без волнений', 'не оставит равнодушным', 'не открыть ничего нового', 'не поверить глазам',
    'не пройти незамеченным', 'не секрет', 'не стоит прогибаться', 'не так ли', 'не ты, так тебя', 'нерадивый',
    'несколько интересных идей', 'нет и не было', 'ни для кого не секрет', 'ничто не предвещало беды',
    'ничто человеческое не чуждо', 'ничтоже сумявшись', 'новоиспечённый', 'новшество',
    'новый веток', 'нынешние', 'обещает стать', 'обжигать холодом', 'образчик', 
    'объект нападок', 'одержать победу', 'окунуться в атмосферу', 'осенняя хандра',
    'оставлять желать лучшего', 'отечественный', 'переживать рост', 'переступить черту', 'перейти на плоскость',
    'пески времени', 'по достоинству оценить'],

  amplifiers: ['абсолютно', 'безоговорочно', 'безусловно', 'во всех отношениях',
    'вообще', 'вполне', 'всего лишь', 'действительно', 'довольно', 'именно', 'истинно', 'подлинно', 'максимально',
    'наголову', 'наиболее', 'напрочь', 'начисто', 'невероятно', 'неизбежно',  'особенно', 'особо', 'очень',
    'полностью', 'предельно', 'прямо', 'радикально', 'решительно', 'самый', 'совершенно', 'совсем', 'строго', 'сугубо',
    'сущий', 'точно', 'фактически', 'целиком', 'явно'],

  // congratulatoryStamps: ['в любви и понимании', 'волшебный праздник', 'все о чем мечталось', 'искорки',
  //   'к нам стучится', 'лист календаря', 'наполнены радостью', 'не останавливаться на достигнутом',
  //   'неба над головой', 'новые сверщения', 'одна большая семья', 'от осей души', 'открыть новые возможности',
  //   'ощущение чуда', 'позитивные эмоции', 'позитивный настрой', 'праздничное настроение', 'самых амбициозных целей',
  //   'сбудутся все желания', 'светлый праздник', 'сердечно поздравлять', 'символ богатства', 'спешить уступить место',
  //   'спешить искренне поздравить', 'страна детства', 'стучать копытом счастья', 'радости желаем', 'успех во всех делах',
  //   'хозяйка года'],

  pleonasms: ['ареал обитания', 'взлететь вверх', 'видеть своими глазами', 'вместе собираться',
    'вола вольная', 'вырасти в размерах, объеме', 'дело рук человеческих', 'жестоко избит', 'коллега по работе',
    'май месяц', 'окружить со всех сторон', 'повторить скова', 'подняться вверх', 'пожилой старик', 'полным-полно',
    'предварительная предоплата', 'присниться во сне', 'пустая болтовня', 'спуститься вниз', 'старый дед',
    'счет на оплату', 'упасть вниз'],

  timeParasites: ['в настоящее время', 'в наши дни в последнее время', 'в последние годы в современной России',
    'в современном мире', 'до недавнего времени', 'на данный момент', 'на сегодняшний день', 'на текущий момент', 'нынче', 'сейчас'],

  estimates: ['авторитетный', 'активный', 'актуальный', 'астрономический', 'баснословный', 'безмерный', 'безупречный',
    'бескомпромиссный', 'бесчисленный', 'богатый', 'большой', 'быстрый', 'великолепный', 'веский', 
    'внушительный', 'выдающийся', 'высококачественный', 'высококлассный', 'гарантированный', 'глубокий', 'грандиозный',
    'динамичный', 'длительный', 'ДОЛГИЙ', 'достойный', 'желанный', 'заметный', 'замечательный', 'заоблачный', 'заслуженный',
    'знаковый', 'знатный', 'Значимый',  'идеальный', 'известный', 'изрядный', 'изумительный', 'инновационный',
    'интерактивный', 'исторический',  'кваливицированный', 'ключевой', 'колоссальный', 'компетентный', 'короткий',
    'красивый', 'крупный', 'легендарный', 'лучший', 'массивный', 'мастерский', 'масштабный', 'мелкий', 'мощный', 'надежный', 'настоящий',
    'независимый', 'незаурядный', 'неизгладимый', 'неимоверный', 'немаловажный', 'неоценимый', 'нетребовательный', 'низкий', 'ничтожный',
    'новейший', 'объемный', 'обычный', 'ограниченный', 'огромный', 'оптимальный', 'основной', 'отборный', 'ответственный', 'отличный',
    'отменный', 'первоклассный', 'перспективный', 'плохой', 'потрясающий', 'похвальный', 'почтенный', 'превосходный', 'прекрасный',
    'премиальный', 'прибыльный', 'приличный', 'проактивный', 'прорывной', 'простой', 'профессиональный', 'реальный', 'революционный',
    'респектабельный', 'символический', 'системный', 'скромный', 'сногсшибательный', 'современный', 'солидный', 'стабильный', 'стильный',
    'стопроцентный', 'судьбоносный', 'существенный', 'титанический',  'уважаемый', 'ударный', 'удобный', 'уникальный',
    'успешный', 'устойчивый', 'фирменный', 'хороший', 'царский', 'ценный', 'широкий', 'штучный', 'эксклюзивный', 'элементарный', 'эпохальный',
    'эталонным', 'эффективный', 'эффектный'],

  weakVerbs: ['быть', 'должен', 'иметься', 'мочь', 'намереваться', 'находиться',
    'нужен', 'обладать', 'позволять', 'распологаться', 'служить', 'существовать', 'хотеть', 'являться'],


  // householdStamps: ['без промедления', 'в возрасте', 'в нужное русло', 'в общих чертах', 'в разы', 'взрывоопасный', 'волшебным образом',
  //   'вопреки ожиданиям', 'вот где сидит', 'всего-навсего', 'вызывать волну недовольства', 'домашний домочадцы\t', 'завалить', 'засыпать',
  //   'кардинальным образом', 'куда не глянь', 'на каждом шагу', 'на самом деле нет', 'надо понимать', 'направо и налево', 'не доходят руки',
  //   'не зря ведь', 'не составит труда', 'неуёмная энерргия', 'от мала до велика', 'по крупинкам', 'пресловутый', 'просится наружу',
  //   'сводить с ума', 'сойти на нет', 'сплошь и рядом', 'сухой остаток', 'так и норовить', 'то же самое', 'усидеть на месте', 'чадо',
  //   'чего нельзя сказать', 'шалун', 'это действительно так'],


  advertisingStamps: ['в курсе событий',  'взаимовыгодный', 'взыскательный', 'вы оцените по достоикстку',
    'гибкий подход', 'для всей семьи', 'доступная цена', 'единство стиля', 'заряд свежести', 'мегаполис', 'незаменимый', 
    'спутник', 'неординарный', 'неотъемлемая часть', 'непревзойденное качество', 'ничего лишнего', 'премиум-класс', 'придавать сил',
    'прилив сил', 'притязательный', 'приятно удивлён', 'работать на рынке', 'ритм города', 'самые смелые', 'сладкий подарок', 'со вкусом',
    'современный мегаполис', 'современный человек', 'способный воплотить', 'строгая элегантность', 'творческий подгод',
    'творческое решение', 'ценить время'],


  generalizingUncertainties: ['99% случаев', 'без малого',  'в некоторых случаях', 'в общем', 'в районе', 'в среднем', 'в целом',
    'валовый', 'весь',  'всеобщий', 'всякий', 'всяческий', 'всех мастей', 'зачастую', 'ит.д', 'кто попало',
    'кто угодно', 'любой', 'многие', 'множество', 'нередко', 'одна из', 'около', 'ориентировочно', 'плюс-минус', 'повальный',
    'повсеместный', 'поголовный', 'порядка', 'почти', 'практически', 'приблизительно', 'примерно', 'различный', 'разного рода',
    'разнообразный',  'совокупный', 'сотни', 'сплошной', 'сплошь и рядом', 'то и дело', 'тысячи', 'целый', 'энный'],


  introductory: ['кроме шуток', 'кстати', 'мало того', 'между нами', 'между прочим', 'мол', 'мягко говоря', 'на минуточку',
    'на мой взгляд',  'наверное', 'надо полагать', 'называется', 'наконец', 'напротив', 'небось', 
    'однако', 'одним словом', 'оказывается', 'откровенно', 'очевидно', 'по идее', 'по мне', 'по определению', 'по правде',
    'по сути', 'по-моему', 'поди', 'пожалуй', 'положим', 'помнится', 'понятно', 'похоже', 'представь', 'прежде всего',
    'признаюсь', 'проще говоря', 'разумеется', 'с одной стороны', 'само собой', 'серьезно', 'скажем', 'скорее',
    'слава богу',  'случайно', 'собственно', 'соответственно', 'спрашивается', 'стало быть', 'строго говоря',
    'судя по всему', 'так вот', 'точнее', 'хорошо', 'часом', 'честно говоря', 'что называется', 'что ни говори',
    'без сомнения',  'более того', 'в итоге', 'в конце концов',  'в принципе',
    'в сущности', 'вдобавок', 'вернее', 'видать', 'видимо', 'во-первых', 'во-вторых',  'воистину',
    'вообразите', 'впрочем', 'выходит', 'говорят', 'грешным делом', 'грубо говоря', 'далее',  'делать нечего',
    'должно быть', 'допустим', 'другими словами', 'думаю', 'если позволите', 'если честно', 'естественно', 'знаете ли', 'значит',
    'и вообще', 'и правда', 'иначе говоря', 'к несчастью', 'к огорчению', 'к примеру', 'к сведению', 'к слову', 'к сожалению',
    'к стыду', 'кажется', 'кажись',  'как 6ы то ни было', 'как видите', 'как водится', 'как всегда', 'как выяснилось',
    'как говорится', 'как известно', 'как назло', 'как ни странно', 'как обычно', 'как оказалось', 'как правило', 'как принято',
    'конечно', 'короче']
}