import React from 'react'
import {useState, useEffect} from "react";
import {useHttp} from "../../hooks/http.hook";
import {useMessage} from "../../hooks/message.hook";
import { useDispatch } from 'react-redux'
import { login } from '../../redux/actions/auth.actions'
import { useLocation, useNavigate } from 'react-router-dom'

export const AuthPage = ({isLogin = true}) => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()
  const message = useMessage()
  const {loading, request, error, clearError} = useHttp()
  const [form, setForm] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  const changeHandler = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const registerHandler = async () => {
    try {
      const data = await request('/api/auth/register', 'POST', {...form})
      message(data.message)
    } catch (e) {}
  }

  const loginHandler = async () => {
    try {
      const data = await request('/api/auth/login', 'POST', {...form})
      message(data.message)
      dispatch(login({token: data.token, user: data.user}))
      location.state?.from?.pathname ? history(location.state?.from?.pathname) : history('/')
    } catch (e) {}
  }

  return (
    <div className="row justify-content-center align-items-center center_wrapper">
      <div className="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-5 col-xxl-4">

        <div className="card p-5">
          <h3 className="text-2xl">{isLogin ? `Войти` : `Зарегистрироваться`}</h3>
          <span className="text-gray">Codeminer</span>

          <div className="card-body p-0 pt-3">
            {/*Form*/}
            <div className="input-group mb-3">
              <input
                placeholder="Введите email"
                type="text"
                id="email"
                className="form-control"
                name="email"
                value={form.email}
                autoComplete="off"
                onChange={changeHandler}
              />
            </div>
            <div className="input-group mb-3">
              <input
                placeholder="Введите пароль"
                type="password"
                id="password"
                className="form-control"
                name="password"
                value={form.password}
                onChange={changeHandler}
              />
            </div>

            {/*Buttons*/}
            {isLogin ?
              <>
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-bg-blue w-100 text-base"
                      onClick={loginHandler}
                      disabled={loading}
                    >
                      Войти
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-my w-100 text-base"
                      onClick={registerHandler}
                      disabled={loading}
                    >
                      Регистрация
                    </button>
                  </div>
                </div>
              </>
              :
              <>
                <div className="row">
                  <div className="col-6">
                    <button
                      className="btn btn-bg-blue w-100 text-base"
                      onClick={registerHandler}
                      disabled={loading}
                    >
                      Регистрация
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="btn btn-outline-my w-100 text-base"
                      onClick={loginHandler}
                      disabled={loading}
                    >
                      Войти
                    </button>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>
    </div>
  )
}
