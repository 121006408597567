import { ARCH_CLEAR, ARCH_UPDATE } from '../types/types'

export const updateForm = (data) => {
  return {
    type: ARCH_UPDATE,
    payload: data
  }
}

export const clearForm = () => {
  return {
    type: ARCH_CLEAR,
  }
}