import { icons } from './Icons'

export const PopupEl = ({onClick, text}) => {
  return (
    <div onClick={onClick} className="d-flex justify-content-between align-items-center popEl">
      <span className="text-sm f-regular">{text}</span>
      <span>{icons.rBracket()}</span>
    </div>

  )
}