import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHttp } from '../hooks/http.hook'
import { InlineLoader } from './InlineLoader'
import TreeNode from './TreeNode'

export const TreeView = ({fileClicked, theme}) => {
  const auth = useSelector(state => state.auth)
  const {loading, request, error, clearError} = useHttp()
  const [tree, setTree] = useState('Пока нет файлов')
  const [currentPath, setCurrentPath] = useState({ path: '' })


  useEffect(() => {
    if (auth.user) {
      try {
        (async () => {
          const data = await request('/api/filemanager/getTree', 'POST', {userMail: auth.user.email}, {authorization: 'Bearer ' + auth.token})
          console.log(data.tree)
          // console.log(typeof data.tree)
          setTree(data.tree)
        })()
      } catch (e) {
        console.log(e)
      }
    }
  }, [auth.user])
  
  useEffect(() => {
    // console.log(currentPath)
    if (!currentPath.children) {
      // console.log('click on file, get it content')
      fileClicked(currentPath)
    }
  }, [currentPath])

  return (
    <div className={`fileManager ${theme}`}>
      {loading ? <InlineLoader /> :
      <>
        {/*<p>{currentPath.path}</p>*/}
        {typeof tree === 'object' ? <TreeNode node={tree} level={0} currentPath={currentPath} setCurrentPath={setCurrentPath} /> : tree}
      </>
      }
    </div>
  )
}