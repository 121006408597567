import { Layout } from '../../components/Layout'
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  ReactFlowProvider,
  removeElements,
  Handle, ControlButton
} from 'react-flow-renderer'
import { FlowSidebar } from './FlowSidebar'
import { BlockForm } from './BlockForm'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import { updateForm } from '../../redux/actions/arch.actions'
import { Icon } from '../../components/Icon'
import { useMessage } from '../../hooks/message.hook'
import { useHttp } from '../../hooks/http.hook'
import { useNavigate } from 'react-router-dom'



export const Blocks = () => {
  const history = useNavigate()
  const auth = useSelector(state => state.auth)
  const { request } = useHttp()
  const arch = useSelector(state => state.arch)
  const dispatch = useDispatch()
  const message = useMessage()
  const reactFlowWrapper = useRef(null)
  const [reactFlowInstance, setReactFlowInstance] = useState(null)

  // const edgeType = 'smoothstep'
  const edgeType = 'default'
  const snapGrid = [12, 12]

  const [elements, setElements] = useState([])
  const [blockId, setBlockId] = useState(-1)
  const [show, setShow] = useState(false)

  // load archStr and create the flow saved in archStr (compare with existing blocks in arch and  mark deleted)
  const updateFlow = () => {
    if (arch && arch.blocks.length && arch.archStr[arch.appType]) { // && !elements.length) {
      let loadedEls = JSON.parse(arch.archStr[arch.appType])
      loadedEls = loadedEls.map(el => {
        if (el.id[0] === 'd') {
          let pBlock = arch.blocks.find(b => +b.id === +el.data.b.id)
          if (pBlock) {
            // console.log(`el: ${JSON.stringify(el.data.b, null, 2)}\nblock: ${JSON.stringify(pBlock, null, 2)}`)
            el.data.b = { ...pBlock }
            // el.data.b = JSON.parse(JSON.stringify(pBlock))
          } else {
            if (!el.data.b.name.includes('DELETED!')) el.data.b.name = `DELETED! ${el.data.b.name}`
          }
        }
        return el
      })
      setElements(loadedEls)
    }
  }
  useEffect(() => {
    updateFlow()
  }, [arch.blocks])

  // =======================================================================
  // Flow start
  const saveFlow = (flow) => {
    let as = {
      ...arch.archStr,
      [arch.appType]: JSON.stringify(flow)
    }
    dispatch(updateForm({ archStr: { ...as } }))
  }

  useEffect(() => {
    if (reactFlowInstance) {
      saveFlow(reactFlowInstance.toObject().elements)
    }
    let objBlocks = {}
    for (let i = 0; i < elements.length; i++) {
      try {
        objBlocks[elements[i].data.b.name] = elements[i].data.b.id
      } catch { }
    }
    try {
      const data = request('/api/arch/createFolder', 'POST', { blocks: objBlocks, user: auth.user.email, type: arch.appType }, { authorization: 'Bearer ' + auth.token })
      message(data.message)
    } catch (e) { console.log(e) }
    //
  }, [elements])

  const onConnect = useCallback(
    (params) =>
      setElements((els) =>
        addEdge({ ...params, type: edgeType }, els)
        // addEdge({ ...params, type: edgeType, style: { stroke: '#f00' } }, els)
        // addEdge({ ...params, animated: true, style: { stroke: '#f00' } }, els)
      ),
    []
  )

  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els))

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance)

  const onDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }

  const onDragStop = (event) => {
    event.preventDefault()
    // reSet Elements, it's force save Flow to DB
    setElements(els => [...els])
  }

  const onElementClick = (event, node) => {
    event.preventDefault()
    setBlockId(node.data.b.id)
    setShow(true)
  }

  const onDrop = (event) => {
    event.preventDefault()

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
    const type = event.dataTransfer.getData('application/reactflow')
    const b = JSON.parse(event.dataTransfer.getData('b')) // data from sidebar component
    // console.log(b)
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    })
    const newNode = {
      id: `dndnode_${+ new Date()}`,
      type,
      position,
      data: { label: `${type} node`, b },
    }

    setElements((es) => es.concat(newNode))

  }

  const CustomNode = ({ data, id }) => {
    let b = data.b
    // console.log('Data in component', data)
    return (
      <>
        <Handle
          type="target"
          position="top"
        />

        <div className="d-flex justify-content-start" style={{ borderRadius: '5px', boxShadow: '2px 2px 2px #e2e2e2', padding: '10px', backgroundColor: '#fff', border: '1px solid #e2e2e2', maxWidth: '250px', minWidth: '250px' }}>
          <div className="">
            {b.type === 'Action' ? <Icon name='flash' size='20px' mt='6px' mr='0' /> : <Icon name='box' size='20px' mt='6px' mr='0' />}
          </div>
          <div className="d-flex flex-column w-100">
            <div className="blockin d-flex flex-column">
              <div className="blocktext">
                <p className="blocktitle" style={{ color: b.name.includes('DELETED!') ? 'red' : 'black' }}>{b.name}</p>
                <p className="blockdesc">{b.description}</p>
              </div>
              <div className="d-flex justify-content-end" style={{ marginTop: '5px' }}>
                <i
                  style={{ cursor: 'pointer' }}
                  className="fa fa-trash-o text-gray clear-minWidth flow-icon"
                  aria-hidden="true"
                  onClick={
                    () => {
                      //

                      try {
                        const del = request('/api/arch/deleteFolder', 'POST', { name: data.b.name, id: data.b.id, user: auth.user.email }, { authorization: 'Bearer ' + auth.token })
                        message(del.message)
                      } catch (e) { }
                      //
                      //console.log('clicked delete:', `Node id ${id}, Block id: ${b.id}`)
                      // delete Node and linked edges

                      setElements(prev => prev.filter(el => el.id !== id).filter(el => el.target !== id).filter(el => el.source !== id))
                      setShow(false)
                    }
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <Handle
          type="source"
          position="bottom"
        />
      </>
    )
  }
  // Flow end

  // Edit block
  const updateOnChange = async () => {
    updateFlow()
    // hide sidebar
    setShow(false)
  }

  const showModal = (id = -new Date()) => {
    setBlockId(id)
    // show sidebar
    setShow(true)
  }

  const createBlock = () => {
    showModal()
  }

  const onClose = () => {
    setShow(false)
  }
  // Edit block end
  // =======================================================================

  const downloadAll = async () => {
    try {
      const zip = await request('/api/arch/toZip', 'POST', { path: auth.user.email }, { authorization: 'Bearer ' + auth.token })
      message(zip.message)
      let link = document.createElement('a')
      // download snippet by arch.appType
      link.href = zip.path
      link.setAttribute('download', 'app.zip')
      message('app.zip')
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
      setTimeout(() => history('/arch'), 3000);
    } catch (e) { }
  }

  return (
    <Layout sidebar={false}>
      <>
        <div className="row h-100">
          <div className="col-12">

            <div className="dndflow">
              {elements &&
                <ReactFlowProvider>
                  <FlowSidebar blocks={arch.blocks} createBlock={createBlock} />
                  <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                    <ReactFlow
                      elements={elements}
                      nodeTypes={{ special: CustomNode }}
                      onConnect={onConnect}
                      onElementsRemove={onElementsRemove}
                      snapToGrid={true}
                      snapGrid={snapGrid}
                      onElementClick={onElementClick}
                      onLoad={onLoad}
                      onDrop={onDrop}
                      onNodeDragStop={onDragStop}
                      onDragOver={onDragOver}
                    >
                      <Background
                        variant="dots"
                        gap={12}
                        size={0.5}
                      />
                      <Controls showZoom={false} showInteractive={false}>
                        <ControlButton onClick={downloadAll}>
                          <i
                            style={{ cursor: 'pointer' }}
                            className="fa fa-download txt-gray clear-minWidth"
                            aria-hidden="true"
                          />
                        </ControlButton>
                      </Controls>
                    </ReactFlow>
                  </div>
                  <BlockForm options={{ blockId, show }} updateOnChange={updateOnChange} onClose={onClose} />
                </ReactFlowProvider>
              }
            </div>

          </div>
        </div>
      </>
    </Layout>
  )
}