import { useWebWorker } from './webworker.hook'
import { stopWords } from '../components/stopWords'
import { WordSpan } from '../components/WordSpan'
import { reactStringReplace } from 'react-string-replace';


export const useCheckWords = () => {
  const { working, initWorker } = useWebWorker()
  const legend = {
    corporateStamps: { title: 'Корпоративные штаммпы', advice: 'Кажется, тут можно сократить' },
    newspaperStamps: { title: 'Газетные штаммпы', advice: 'Читателю трудно прорываться сквозь статью, в которой много подобных фраз' },
    amplifiers: { title: 'Усилители', advice: 'Такими словами пытаются усилить оценку. Но сама по себе оценка не убеждает, если не подкреплена фактами или иллюстрациями. Поработайте с оценкой' },
    congratulatoryStamps: { title: 'Поздравительные штампы', advice: 'Такие поздравления довольно распространенные, из-за этого неинтересные. Лучше написать персональне поздравление без типовых фраз.' },
    pleonasms: { title: 'Плеоназмы', advice: 'Избыточное словосочетание. Сократите до одного слова, чтобы меньше нагружать читателя' },
    estimates: { title: 'Оценки', advice: 'Лучше удалить или доказать фактами' },
    weakVerbs: { title: 'Слабые глаголы', advice: 'Обозначает состояние, а не действие. Попробуйте убрать или замените на глагол действия' },
    timeParasites: { title: 'Паразиты времени', advice: 'Попробуйте убрать, уточните или противопоставьте прошлому или будущему' },
    householdStamps: { title: 'Бытовые штампы', advice: 'Лучше заменить на одно слово' },
    advertisingStamps: { title: 'Рекламные штампы', advice: 'Читатель наверняка уже видел эти фразы в рекламе, ему будет неинтересно. Лучше заменить на фотографии, иллюстрации, примеры и случаи из жизни' },
    generalizingUncertainties: { title: 'Обобщающие', advice: 'Текст не пострадает, если это слово удалить' },
    introductory: { title: 'Вводные', advice: 'Лучше заменить на более походящее слово' },
  }
  //#DA570F
  const placeCaretAtEnd = (el) => {
    el.focus()
    if (typeof window.getSelection != "undefined"
      && typeof document.createRange != "undefined") {
      let range = document.createRange()
      range.selectNodeContents(el)
      range.collapse(false)
      let sel = window.getSelection()
      sel.removeAllRanges()
      sel.addRange(range)
    } else if (typeof document.body.createTextRange != "undefined") {
      let textRange = document.body.createTextRange()
      textRange.moveToElementText(el)
      textRange.collapse(false)
      textRange.select()
    }
  }

  const removeHighlight = (innerHTML) => {
    console.log("innerHTML--", innerHTML)
    let i = innerHTML.replaceAll(`<span>`, "")
    i = i.replaceAll('</span>', "")
    innerHTML = i
    console.log("done", innerHTML)
    //placeCaretAtEnd(innerHTML)
  }

  const removeAllHighlight = (element) => {
    console.log(element)
    Object.keys(legend).forEach(key => {
      removeHighlight(element)
    })
    return element
  }

  const check = ({ innerHTML, stopW, legend }) => {

    const highliter = (word, innerHTML, category, advice) => {
      let rgxp = new RegExp(word, 'g')
      let repl = `<span id='${word}' name='${word}' class='glvrd' onMouseOver="showIssue('${category}', '${advice}')" onClick="Skip(this.id)">${word}</span>`
      // let q = reactStringReplace(innerHTML, word, (match, i) => (
      //   <span>{match}</span>
      // ));
      let res = innerHTML.replace(rgxp, repl)
      return res
    }

    Object.keys(stopW).forEach(category => {
      stopW[category].forEach(word => {
        innerHTML = highliter(word, innerHTML, legend[category].title, legend[category].advice)
      })
    })

    return innerHTML
  }

  const runCheck = initWorker(check)

  const checkIt = (innerHTML) => {
    return runCheck({
      innerHTML,
      stopW: JSON.parse(JSON.stringify(stopWords)),
      legend: JSON.parse(JSON.stringify(legend))
    })
  }

  return { legend, working, placeCaretAtEnd, removeAllHighlight, checkIt }
}