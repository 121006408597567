import {Routes, Route, Navigate} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Arch } from './pages/architecture/Arch'
import { UserProfilePage } from './pages/user/UserProfilePage'
import { UserPasswordPage } from './pages/user/UserPasswordPage'
import { AuthPage } from './pages/user/AuthPage'
import { NotFound } from './pages/NotFound'
import { RequireAuth } from './hoc/RequireAuth'
import { Blocks } from './pages/architecture/Blocks'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { checkLogged } from './redux/actions/auth.actions'
import { Loader } from './components/Loader'
import { Code } from './pages/code/Code'
import { Docs } from './pages/docs/Docs'
import { Tests } from './pages/tests/Tests'
import { Settings } from './pages/tests/Settings'
import { Apps } from './pages/tests/Apps'
import { Cases } from './pages/tests/Cases'

function App() {
  const dispatch = useDispatch()
  const [checking, setChecking] = useState(true)

  useEffect(() => {
    // check local storage and load to store
    dispatch(checkLogged()).then(res => {
      setChecking(false)
    })
  }, [])

  if (checking) return <Loader />

  return (
    <>
      <ToastContainer hideProgressBar />

      <Routes>
        <Route path="/user/register" element={<AuthPage isLogin={false} />} />
        <Route path="/user/login" element={<AuthPage />} />

        {/*AUTH NEED Start*/}
        <Route path="/" element={<Navigate to="/arch" replace />} />

        <Route path="/arch" element={
          <RequireAuth>
            <Arch />
          </RequireAuth>
        } />

        <Route path="/arch/blocks" element={
          <RequireAuth>
            <Blocks />
          </RequireAuth>
        } />

        <Route path="/user" element={<Navigate to="/user/profile" replace />} />
        <Route path="/user/profile" element={
          <RequireAuth>
            <UserProfilePage />
          </RequireAuth>
        } />

        <Route path="/user/password" element={
          <RequireAuth>
            <UserPasswordPage />
          </RequireAuth>
        } />

        <Route path="/code" element={
          <RequireAuth>
            <Code />
          </RequireAuth>
        } />

        <Route path="/docs" element={
          <RequireAuth>
            <Docs />
          </RequireAuth>
        } />

        <Route path="/test" element={<Navigate to="/test/dashboard" replace />} />
        <Route path="/test/dashboard" element={
          <RequireAuth>
            <Tests />
          </RequireAuth>
        } />

        <Route path="/test/cases" element={
          <RequireAuth>
            <Cases />
          </RequireAuth>
        } />

        <Route path="/test/integration" element={
          <RequireAuth>
            <Settings />
          </RequireAuth>
        } />

        <Route path="/test/apps" element={
          <RequireAuth>
            <Apps />
          </RequireAuth>
        } />

        {/*AUTH NEED End*/}

        <Route path="*" element={<NotFound />} />

        {/*<Route path="/" element={<Layout />}>*/}
        {/*  <Route index element={<Register />} />*/}
          {/*<Route path="about" element={<About />}>*/}
          {/*  <Route path="contacts" element={<p>Our contact</p>} />*/}
          {/*  <Route path="team" element={<p>Our team</p>} />*/}
          {/*</Route>*/}
          {/*<Route path="about-us" element={<Navigate to="/about" replace />} />*/}
          {/*<Route path="posts" element={<Blogpage />} />*/}
          {/*<Route path="posts/:id" element={<Singlepage />} />*/}
          {/*<Route path="posts/:id/edit" element={<Editpost />} />*/}
          {/*<Route path="posts/new" element={*/}
          {/*  <RequireAuth>*/}
          {/*    <Createpost />*/}
          {/*  </RequireAuth>*/}
          {/*} />*/}
          {/*<Route path="login" element={<LoginPage />} />*/}
          {/*<Route path="*" element={<Notfoundpage />} />*/}
        {/*</Route>*/}
      </Routes>
    </>
  )
}
export default App;
