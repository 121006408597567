import { Layout } from '../../components/Layout'
import { useMessage } from '../../hooks/message.hook'

export const Apps = () => {
  const message = useMessage()

  const download = () => {
    let link = document.createElement('a')
    link.href = '/assets/presets/recorder-1.0-mac.zip'
    link.setAttribute('download', 'recorder-1.0-mac.zip')
    message('Скачивается')
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)
  }

  return (
    <Layout isTest={true}>
      <div className="container">
        <div className="wrapper">
          <p className="m-0 text-2xl f-medium dark">Приложения</p>
          <p className="text-xs coolGray400 mb-4">Скачайте приложения для записи и запуска тест-кейсов</p>

          <p className="m-0 text-lg dark">RecorderApp</p>
          <p className="text-xs coolGray400 mb-4">Приложение для записи и воспроизведения тест-кейсов <br/>
            созданных в рамках данной организации</p>

          <button className="btn btn-bg-blue minWidth-136 text-base me-2" onClick={download}>Скачать</button>

        </div>
      </div>
    </Layout>
  )
}