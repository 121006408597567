import React, { useState } from 'react'

const OFFSET = 16

export default function TreeNode({ node, level = 0, currentPath, setCurrentPath }) {
  const [collapsed, setCollapsed] = useState(true)

  return (
    <div className='SideBar-TreeNode'>
      <div className={'TreeNode-Name' + (currentPath.path === node.path ? ' TreeNode-Current' : '')}
           onClick={() => {
             setCollapsed(prev => !prev)
             setCurrentPath(node)
           }}
           style={{ paddingLeft: 8 + (level || 0) * OFFSET + 'px' }}>

        {!node.hasOwnProperty('children') ? <i className="fa fa-file-text-o" aria-hidden="true" /> : !collapsed ? <i className="fa fa-folder-open-o" aria-hidden="true" />
          :
          <i className="fa fa-folder-o" aria-hidden="true" />
        } {node.name || 'root'}

      </div>
      {!!node.children && !!node.children.length && !collapsed &&
        <div className="TreeNode-Children">
          {node.children.sort((a, b) => (a.children && b.children) || (!a.children && !b.children) ? 0 : a.children && !b.children ? -1 : 1 ).map((item, index) => {
            return <TreeNode
              key={index} node={item} level={level + 1} currentPath={currentPath} setCurrentPath={setCurrentPath}
            />
          })}
        </div>
      }
    </div>
  )
}