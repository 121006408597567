import { Layout } from '../../components/Layout'
import CodeMirror from '@uiw/react-codemirror'
import { linter, openLintPanel } from '@codemirror/lint'
import { javascript, esLint } from '@codemirror/lang-javascript'
import { php, phpLanguage } from '@codemirror/lang-php'
import { cpp, cppLanguage } from '@codemirror/lang-cpp'
import { python, pythonLanguage } from '@codemirror/lang-python'
import Linter from 'eslint4b-prebuilt'
import { useEffect, useRef, useState } from 'react'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { TreeView } from '../../components/TreeView'
import { useSelector } from 'react-redux'
import { useHttp } from '../../hooks/http.hook'
import { useMessage } from '../../hooks/message.hook'
import { useNavigate } from 'react-router-dom'


/**
 *  to use jshint (nodeJS polifills)
 *
 *  npm install react-app-rewired --save // allow modify webpack config
 *  npm install node-polyfill-webpack-plugin --save
 *
 *  in root create config-overrides.js and write inside this
 *
 *      module.exports = function override(config, env) {
 *        config.plugins.push(new NodePolyfillPlugin({
 *          excludeAliases: ['console']
 *        }))
 *        return config
 *      }
 *
 * @returns {JSX.Element}
 * @constructor
 */

let lang = {
  js: [[javascript({ jsx: true }), linter(esLint(new Linter()))], "snippet.js"],
  php: [[php(), phpLanguage], "snippet.php"],
  cpp: [[cpp(), cppLanguage], "snippet.cpp"],
  py: [[python(), pythonLanguage], "snippet.py"],
  md: [[markdown({ base: markdownLanguage })], "snippet.md"],
}

export const Code = () => {
  const auth = useSelector(state => state.auth)
  const { loading, request, error, clearError } = useHttp()
  const codeRef = useRef(null)
  const view = useRef(null)
  const [theme, setTheme] = useState('dark')
  const [extensions, setExtensions] = useState(lang.js[0])
  const [language, setLanguage] = useState(lang.js[1])
  const [editingFile, setEditingFile] = useState(null)
  const [value, setValue] = useState('print("Hello, Python!")\nconsole.log("Hello, JavaScript!")\n<?php echo "Hello, World!";?>\nstd::cout << "Hello, C++!";\n_**`Hello, Markdown`**_')
  const [err, setErr] = useState(false)
  const message = useMessage()//
  const history = useNavigate()//

  const changeHandler = (e) => {
    setExtensions(lang[e.target.value][0])
    setLanguage(lang[e.target.value][1])
  }

  // show linter panel
  // useEffect(() => {
  //   if (view && view.current && view.current.view) openLintPanel(view.current.view)
  // }, [])

  const downloadTxtFile = async () => {
    try {
      const zip = await request('/api/arch/toZip', 'POST', { path: auth.user.email }, { authorization: 'Bearer ' + auth.token })
      message(zip.message)
      let link = document.createElement('a')
      // download snippet by arch.appType
      link.href = zip.path
      link.setAttribute('download', 'app.zip')
      message('app.zip')
      document.body.appendChild(link)
      link.click()
      // setTimeout(() => history('/arch'), 3000);

    } catch (e) {
      message("Пока нечего скачивать")
    }

  }

  const getFileContent = async (path) => {
    try {
      const data = await request('/api/filemanager/getContent', 'POST', { path }, { authorization: 'Bearer ' + auth.token })
      setValue(data.content)
    } catch (e) {
      setValue('')
      setErr(true)
      console.log(e)
    }
  }

  const saveFileContent = async (path) => {
    if (!err) {
      try {
        const data = await request('/api/filemanager/saveContent', 'POST', { path, content: value }, { authorization: 'Bearer ' + auth.token })
      } catch (e) {
        console.log(e)
      }
    } else {
      setErr(false)
    }
  }

  const fileClicked = async (node) => {
    if (node && node.path) {
      if (editingFile && editingFile.path) {
        await saveFileContent(editingFile.path, value)
      }
      setEditingFile(node)
      await getFileContent(node.path)
    }
  }

  return (
    <Layout sidebar={false}>
      <div className="container">
        <div className="wrapper">

          <div className="filemanager">
            <TreeView fileClicked={fileClicked} theme={theme} />
            <CodeMirror
              ref={view}
              value={value}
              height="500px"
              style={{ width: '100%' }}
              extensions={extensions}
              theme={theme}
              onChange={(value, viewUpdate) => {
                // codeRef.current = value
                setValue(value)
                // console.log('value:', value)
                // console.log('viewUpdate:', viewUpdate)
              }}
            />
          </div>

          <div className="container">
            <div className="row">
              <div className="col">
                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  // console.log(codeRef.current)
                  downloadTxtFile()
                }}>Сохранить</button>

                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  openLintPanel(view.current.view)
                }}>Показать ошибки</button>

                <button className="btn btn-bg-blue minWidth-136 text-base mt-4 me-2" onClick={() => {
                  theme === 'dark' ? setTheme('light') : setTheme('dark')
                }}>Сменить тему</button>
              </div>
              <div className="col-2">
                <select
                  name="Lang"
                  className="form-select form-control minWidth-136 text-base mt-4 me-2"
                  onChange={changeHandler}
                >
                  <option value={'js'}>JavaScript</option>
                  <option value={'php'}>PHP</option>
                  <option value={'cpp'}>C++</option>
                  <option value={'py'}>Python</option>
                  <option value={'md'}>Markdown</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}