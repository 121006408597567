import { icons }  from './Icons'
import { NavLink } from 'react-router-dom'

export const Sidebar = ({className = '', visible = true, isTest = false}) => {
  
  const selected_sidebar_item = ({ isActive }) => {
    return isActive ? 'sideBar_el sideBar_el_active'  : 'sideBar_el '
  }

  return (
    <div className={`${className} sideBar ${!visible && 'd-none'}`}>
      
      <span className="sideBar_title">{isTest ? 'Меню' : 'Настройки'}</span>

      {isTest ?
        <>
          <NavLink to="/test/dashboard" className={selected_sidebar_item} ><span>{icons.mosaic()}</span>Дешбоард</NavLink>
          <NavLink to="/test/cases" className={selected_sidebar_item}><span>{icons.mosaic()}</span>Тест кейсы</NavLink>
          <NavLink to="/test/integration" className={selected_sidebar_item}><span>{icons.mosaic()}</span>Интеграции</NavLink>
          <NavLink to="/test/apps" className={selected_sidebar_item}><span>{icons.mosaic()}</span>Приложения</NavLink>
        </>
        :
        <>
          <NavLink to="/user/profile" className={selected_sidebar_item} ><span>{icons.mosaic()}</span>Профиль</NavLink>
          <NavLink to="/user/password" className={selected_sidebar_item}><span>{icons.mosaic()}</span>Пароль</NavLink>
        </>
      }

    </div>
  )
  
}