import { Layout } from '../../components/Layout'
import React, { useCallback, useEffect, useState } from 'react'
import { useHttp } from '../../hooks/http.hook'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/actions/auth.actions'
import { useMessage } from '../../hooks/message.hook'
import { InlineLoader } from '../../components/InlineLoader'

export const Settings = () => {
  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const { loading, request, error, clearError } = useHttp()
  const message = useMessage()
  const [form, setForm] = useState({
    jiraUrl: auth.user.jiraUrl || '',
    jiraName: auth.user.jiraName || '',
    jiraPass: auth.user.jiraPass || '',
    jiraProject: auth.user.jiraProject || '',
  })
  const [jiraProjects, setJiraProjects] = useState([])
  const [jiraConnected, setJiraConnected] = useState(false)
  const [jiraAlreadyConnected, setJiraAlreadyConnected] = useState(false)

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError])

  // Jira project integration completed
  useEffect(() => {
    let jiraConn = auth.user.jiraUrl && auth.user.jiraName && auth.user.jiraPass && auth.user.jiraProject
    if (jiraConn) {
      setJiraAlreadyConnected(true)
      setJiraConnected(true)
    }
  }, [auth.user])

  // get jira
  const getJiraProjects = useCallback(async (token, form) => {
    try {
      const data = await request(`/api/jira/allprojects`, 'POST', { ...form }, { authorization: 'Bearer ' + token })

      if (data.connected && data.projects.length) {
        setJiraProjects(data.projects)
        if (!form.jiraProject) {
          setForm(prev => {return { ...prev, jiraProject: data.projects[0].key }})
        }

        setJiraConnected(true)
      } else {
        message('В Jira пока нет проектов')
      }

      message(data.message)

    } catch (e) {
      console.log(e)
    }
  }, [request, message])

  useEffect(() => {
    (async ()=> {
      if (auth && auth.user && auth.user.jiraUrl) {
        await getJiraProjects(auth.token, form)
      }
    })()
  }, [auth])

  // update form
  const changeHandler = (event) => {
    setForm(prev => { return { ...prev, [event.target.name]: event.target.value } })
  }

  // save form to user
  const updateHandler = async () => {
    try {
      const user = { ...auth.user, ...form }
      dispatch(login({ token: auth.token, user }))
      const data = await request('/api/auth/updateProfile', 'POST', {...form, email: auth.user.email}, {authorization: 'Bearer ' + auth.token})
      message(data.message)
    } catch (e) {}
  }

  // connect to jira and get all projects
  const connectJiraHandler = async () => {
    try {
      await getJiraProjects(auth.token, form)
    } catch (e) {
      console.log(e)
    }
  }

  // remove all jira data from user
  const disconnectJiraHandler = async () => {
    try {
      let frm = {
        jiraUrl: '',
        jiraName: '',
        jiraPass: '',
        jiraProject: '',
      }
      setForm(frm)
      const user = { ...auth.user, ...frm }
      dispatch(login({ token: auth.token, user }))
      const data = await request('/api/auth/updateProfile', 'POST', {...frm, email: auth.user.email}, {authorization: 'Bearer ' + auth.token})
      message(data.message)

      setJiraAlreadyConnected(false)
      setJiraConnected(false)
      setJiraProjects([])
    } catch (e) {
      console.log(e)
    }

  }


  return (
    <Layout isTest={true}>
      <div className="container">
        <div className="wrapper">
          {/*JIRA*/}
          <>
            <div className="row flex-column">

              <div className="col">
                <div className="min-vw-100 mb-0 text-2xl f-medium dark">Интеграции {loading &&
                  <InlineLoader size={20} ml={1}/>}</div>
                <p className="text-xs coolGray400 mb-4">Настройте интеграцию к проекту Jira</p>
              </div>

              {!jiraConnected ?
                // Connect login+pass
                <div className="col-4">
                  <div className="input-field mb-3">
                    <input
                      placeholder="Server URL"
                      type="text"
                      id="url"
                      className="form-control"
                      name="jiraUrl"
                      value={form.jiraUrl}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="input-field mb-3">
                    <input
                      placeholder="Имя пользователя"
                      type="text"
                      id="username"
                      className="form-control"
                      name="jiraName"
                      value={form.jiraName}
                      onChange={changeHandler}
                    />
                  </div>

                  <div className="input-field mb-3">
                    <input
                      placeholder="Пароль"
                      type="password"
                      id="password"
                      className="form-control"
                      name="jiraPass"
                      value={form.jiraPass}
                      onChange={changeHandler}
                    />
                  </div>

                  {/*Test connection*/}
                  <button
                    className="btn btn-outline-gray w-100"
                    onClick={connectJiraHandler}
                    disabled={loading}
                  >
                    Подключить
                  </button>
                </div>
                :
                // Select Jira projects
                <>
                  <div className="col-6">
                    {/*Jira Project*/}
                    <div className="row mb-3">
                      {/*Label*/}
                      <div className="col-6 align-self-center">
                        <span>Выберите проект разработки:</span>
                      </div>

                      {/*Select*/}
                      <div className="col-6">
                        <div className="input-field">
                          <select
                            id="jiraProject"
                            name="jiraProject"
                            className="form-select"
                            value={form.jiraProject}
                            onChange={changeHandler}
                          >
                            {jiraProjects.length ? jiraProjects.map(p => <option key={p.id}
                                                                                 value={p.key}>{p.name}</option>) : (
                              <option value="0">-</option>)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Save / Cancel changes*/}
                  <div className="col-6">
                    <div className="row">
                      <div className="col d-flex justify-content-start">

                        <button
                          className="btn btn-outline-my mb-3"
                          onClick={updateHandler}
                          disabled={loading || !jiraConnected}
                        >
                          {jiraAlreadyConnected ? `${form.jiraUrl} ✓` : 'Подключить'}
                        </button>

                        <button
                          className="btn btn-outline-gray mb-3 ms-2"
                          onClick={disconnectJiraHandler}
                          disabled={loading || !jiraConnected}
                        >
                          Отключить
                        </button>

                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
          </>

        </div>
      </div>
    </Layout>
  )
}